<template>
<div>
<div class="col">
  <spinner x-small v-if="loading && !error"></spinner> 
  <h2 v-else-if="error" class="tw-mt-10 tw-mb-4 tw-font-semibold tw-text-2xl">Terms not found!</h2>
  <div v-else>
    <h2 class="tw-mt-10 tw-mb-4 tw-font-semibold tw-text-2xl">{{item.author.name}} | {{$t('vendor.toc')}}</h2>
    <hr>
  <div class="ml-10 mr-10" v-html="item.tos">
  </div>
  </div>
</div>
</div>
</template>

<script>

export default {

  data () {
        return {
          item: {
            tos: null,
            author: {
              name: null
            }
          },
          error: null,
          loading: false,
        }
  },
  mounted(){
    this.getTerms();
  },
  methods:{
      async getTerms() {
      this.loading = true;
      let uri = '/api/vendor/'+this.$route.params.id+'/tos';
      await this.$http.get(uri)
          .then(({data}) => {
            this.item = data.data
          })
          .catch((error) => {
            this.error = error
          })
          .finally(() => this.loading = false);
    },
  }
 
}

</script>
